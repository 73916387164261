<template>
    <div
        id="level-setting"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="levelSetting"
        aria-hidden="true"
        style="display: none;"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Cài Đặt Phân Quyền Đại Lý</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.stop.prevent="updateLevelInfo">
                    <div class="modal-body">
                        <div class="input-group">
                            <div class="input-group-append">
                                <span class="input-group-text font-bold">
                                    Chọn gói mặc định khi đăng nhập
                                </span>
                            </div>
                            <select v-model="chooseLevel" class="form-control select-left">
                                <option v-for="(level, index) in updateLevel" :key="index" :value="level">
                                    {{ level.name }}
                                </option>
                            </select>
                            <div class="input-group mt-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text font-bold">Chỉnh sửa tên gói</span>
                                </div>
                                <input
                                    type="text"
                                    v-if="chooseLevel"
                                    v-model="chooseLevel.name"
                                    class="form-control select-left"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default waves-effect px-4" data-dismiss="modal">
                            Hủy bỏ
                        </button>
                        <button type="submit" :class="['btn btn-dark-blue waves-effect waves-light px-4']">
                            Cập Nhật
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError } from "../../../helpers"
import { updateLevelInfo } from "@/api/admin"

export default {
    name: "level-setting",
    props: {
        levelList: Array
    },
    data() {
        return {
            updateLevel: [],
            chooseLevel: {}
        }
    },
    watch: {
        levelList() {
            this.updateLevel = this.levelList.slice()
            this.chooseLevel = this.updateLevel.find(level => level.default == 1)
        }
    },
    created() {
        this.chooseLevel = this.updateLevel.find(level => level.default == 1)
    },
    methods: {
        updateLevelInfo: async function() {
            // let isDefault = false
            // for (let level of this.updateLevel) {
            //     if (isDefault && level.default === 1) {
            //         this.$swal("Lỗi", "Vui lòng chỉ chọn duy nhất 1 gói làm mặc định", "error")
            //         return
            //     }
            //     isDefault = level.default === 1 ? true : isDefault
            // }
            // if (!isDefault) {
            //     this.$swal("Lỗi", "Vui lòng chọn một gói làm mặc định", "error")
            //     return
            // }
            this.update = this.updateLevel.map(level => {
                if (level == this.chooseLevel) {
                    level.default = 1
                } else level.default = 0
                return level
            })
            for (let level of this.update) {
                let data = await updateLevelInfo(level.id, level)
                if (data.status !== 200) {
                    this.$swal("Lỗi", catchError(data), "error")
                    return
                }
            }
            this.$swal("Thành Công", "Cập nhật thành công", "success")
            this.$store.commit("APP_RELOAD")
            // this.$store.dispatch("USER_GET_INFO")
            // eslint-disable-next-line no-undef
            $("#level-setting").modal("hide")
        }
    }
}
</script>
