<template>
    <div>
        <h4 class="bold text-uppercase">Quản lý người dùng</h4>
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
                <a
                    class="nav-link active"
                    @click="handleShowPanel('list')"
                    id="home-tab"
                    data-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Danh sách khách hàng</a
                >
            </li>
            <li class="nav-item">
                <a
                    class="nav-link"
                    @click="handleShowPanel('logs')"
                    id="profile-tab"
                    data-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                    >Nhật ký hoạt động khách hàng</a
                >
            </li>
        </ul>
        <div class="tab-content mt-3" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                <div class="row align-items-center">
                    <div class="col-md-3">
                        <div class="card card-orange">
                            <div class="card-body py-2">
                                <h5 class="bold mb-0">
                                    <i class="fas fa-users"></i>
                                    <span class="float-right">{{ clients.length }} Khách hàng</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="card card-info">
                            <div class="card-body py-2">
                                <h5 class="bold mb-0">
                                    <i class="fa fa-dollar"></i>
                                    <span class="float-right">{{ totalCoin | formatNumber }} {{ curency }}</span>
                                </h5>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 text-right">
                        <button
                            data-toggle="modal"
                            href="#client-trans-coin"
                            class="mt-2 mt-md-0 btn bold mr-2 btn-orange btn-md"
                            type="submit"
                        >
                            <i class="fa fa-dollar mr-1"></i>Nạp tiền
                        </button>
                        <button
                            data-toggle="modal"
                            href="#level-setting"
                            class="mt-2 mt-md-0 btn bold btn-dark-blue btn-md"
                            type="submit"
                        >
                            <i class="fas fa-cog mr-1"></i>Cài đặt phân quyền đại lý
                        </button>
                    </div>
                </div>
                <div class="row mt-3 mb-3">
                    <div class="col-md-6 col-12">
                        <div class="row align-items-center">
                            <div class="col-md-auto col-12">
                                <h6 class="bold mb-0">Tìm theo username:</h6>
                            </div>
                            <div class="col-md col-12 mt-2 mt-md-0">
                                <v-select
                                    label="username"
                                    v-model="selectUser"
                                    :options="clients"
                                    @search="onSearch"
                                    placeholder="Nhập username cần tìm"
                                >
                                    <template slot="no-options">
                                        Không tìm thấy danh sách User...
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="input-group">
                            <div class="input-group-append">
                                <span class="input-group-text font-bold">
                                    Tải tối đa
                                </span>
                            </div>
                            <select class="form-control select-left" v-model="limit" @change="getAllUser()">
                                <option value="100">100 User</option>
                                <option value="200">200 User</option>
                                <option value="300">300 User</option>
                                <option value="500">500 User</option>
                                <option value="1000">1000 User</option>
                                <option value="3000">3000 User</option>
                                <option value="5000">5000 User</option>
                                <option value="9999">10000 User</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <button
                            @click="getAllUser()"
                            class="form-control mt-2 mt-md-0 btn-md btn bold btn-dark-blue"
                            type="submit"
                        >
                            <i class="fas fa-sync-alt mr-1"></i>Tải lại danh sách User
                        </button>
                    </div>
                </div>
                <clientlistuser :levelList="levelList" :clients="clients"></clientlistuser>
            </div>
            <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                <clientlogs :panel="panel"></clientlogs>
            </div>
        </div>
        <clientlevelsetting :levelList="levelList"></clientlevelsetting>
        <clienttranscoin @getAllUser="getAllUser()" :clients="clients"></clienttranscoin>
    </div>
</template>
<script>
import menus from "@/config/menu"
import { getAllUser, getListLevels } from "../../../api/admin"
import clientlistuser from "./client-list-user"
import clienttranscoin from "./client-trans-coin"
import clientlevelsetting from "./client-level-setting"
import clientlogs from "./client-logs"
import _ from "lodash"
export default {
    name: "client-manager",
    components: {
        clientlistuser,
        clienttranscoin,
        clientlevelsetting,
        clientlogs
    },
    data() {
        return {
            menus: menus,
            clients: [],
            levelList: [],
            panel: "list",
            selectUser: "",
            limit: 100,
            totalCoin: 0
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        curency() {
            return this.$store.state.site.curency || "Xu"
        },
        reload() {
            return this.$store.state.app.reload
        }
    },
    watch: {
        reload() {
            this.getListLevels()
            this.getAllUser()
        },
        panel(value) {
            if (value === "list") {
                this.getListLevels()
            }
        },
        selectUser(value) {
            if (value) {
                let data = this.clients.find(item => item === value)
                this.clients = []
                this.clients.push(data)
            } else this.getAllUser()
        }
    },
    created() {
        this.getListLevels()
        this.getAllUser()
    },
    methods: {
        onSearch(search, loading) {
            if (search.length > 0) {
                loading(true)
                this.search(loading, search, this)
            }
        },
        search: _.debounce(async (loading, search, vm) => {
            await vm.getAllUser(search)
            loading(false)
        }, 500),
        getListLevels: async function() {
            let data = await getListLevels()
            this.levelList = data.data
        },
        getAllUser: async function(username = null) {
            this.clients = []
            let param = username ? "&username=" + username : ""
            let data = await getAllUser(this.limit, param)
            if (data && data.status === 200) {
                let totalCoin = 0
                this.clients = data.data.map((client, index) => {
                    client.index = index + 1
                    totalCoin += client.coin
                    client.coin_html = client.coin
                    let defaultLevelName = ""
                    for (let level of this.levelList) {
                        if (level.default === 1) {
                            defaultLevelName = level.name
                        }
                    }
                    if (client.levels && client.levels.length > 0) {
                        let level = client.levels.find(lv => lv.site_id === this.site.id)
                        client.level_html = level.name
                    } else client.level_html = defaultLevelName
                    return client
                })
                this.totalCoin = totalCoin
            }
        },
        handleShowPanel: async function(panel) {
            this.panel = panel
        }
    }
}
</script>
