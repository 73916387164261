<template>
    <div>
        <div class="row mb-3">
            <div class="col-md-6 col-sm-12">
                <label for>Tìm theo User name</label>
                <v-select
                    label="username"
                    v-model="selectUser"
                    :options="clients"
                    @search="onSearch"
                    placeholder="Nhập username cần tìm"
                >
                    <template slot="no-options">
                        Không tìm thấy danh sách User...
                    </template>
                </v-select>
                <!-- <input @change="getLogs()" type="text" class="form-control input-light" placeholder="Nhập username cần tìm" v-model="userNameSearching" /> -->
            </div>
            <div class="mt-md-0 mt-2 col-md-6 col-sm-12">
                <label for>Load Limit Logs</label>
                <select class="form-control select-light" v-model="limitLogs" @change="getLogs()">
                    <option value="100">Load 100 logs</option>
                    <option value="200">Load 200 logs</option>
                    <option value="300">Load 300 logs</option>
                    <option value="500">Load 500 logs</option>
                    <option value="1000">Load 1000 logs</option>
                </select>
            </div>
        </div>
        <datatable :columns="columns" :rows="rows"> </datatable>
    </div>
</template>

<script>
import datatable from "@/components/datatable/datatable.vue"
import { getLogsAllUsers, getAllUser } from "../../../api/admin"
import moment from "moment"
import _ from "lodash"

export default {
    name: "TransLogs",
    components: {
        datatable
    },
    props: {
        panel: String
    },
    async created() {
        moment.locale("vi")
        this.showLoading = false
        await this.getLogs()
        this.showLoading = true
    },
    data() {
        return {
            clients: [],
            rows: [],
            showLoading: false,
            userNameSearching: "",
            selectUser: "",
            clients_username: [],
            limitLogs: 100,
            columns: [
                {
                    label: "#",
                    field: "index"
                },
                // {
                //     label: "Tác Giả",
                //     field: "author"
                // },
                {
                    label: "Hành Động",
                    field: "action"
                },
                {
                    label: "Đối Tượng",
                    field: "object_id"
                },
                {
                    label: "UserName",
                    field: "username"
                },
                {
                    label: "Tool",
                    field: "tool_name"
                },
                {
                    label: "Tiền",
                    field: "coin_html",
                    html: true
                },
                {
                    label: "Note",
                    field: "note_html",
                    html: true
                },
                {
                    label: "Trạng Thái",
                    field: "status_html",
                    html: true
                },
                {
                    label: "Ghi Chú",
                    field: "description"
                },
                {
                    label: "Thời Gian Tạo",
                    field: "created_at"
                }
            ]
        }
    },
    watch: {
        reload() {
            this.getLogs()
        },
        panel(value) {
            if (value === "logs") {
                this.getLogs()
            }
        },
        selectUser(value) {
            this.getLogs(value.username)
        }
    },
    methods: {
        getAllUser: async function(username = null) {
            this.clients = []
            let param = username ? "&username=" + username : ""
            let data = await getAllUser(100, param)
            if (data && data.status === 200) {
                this.clients = data.data.map((client, index) => {
                    client.index = index + 1
                    return client
                })
            }
        },
        onSearch(search, loading) {
            if (search.length > 0) {
                loading(true)
                this.search(loading, search, this)
            }
        },
        search: _.debounce(async (loading, search, vm) => {
            await vm.getAllUser(search)
            loading(false)
        }, 500),
        getLogs: async function(search = null) {
            this.rows = []
            let data = await getLogsAllUsers(this.limitLogs, search)
            if (data.status === 200 && data.success) {
                this.rows = data.data.map((log, index) => {
                    log.index = index + 1
                    log.old_coin_html = Number(log.old_coin).toLocaleString("it-IT")
                    log.new_coin_html = Number(log.new_coin).toLocaleString("it-IT")
                    log.change_coin = (Number(log.new_coin) - Number(log.old_coin)).toLocaleString("it-IT")
                    log.note_html =
                        '<input type="text" class="form-control" style="width: 200px" value="' +
                        log.notes.replace(/"/g, "'") +
                        '"/>'
                    log.status_html =
                        parseInt(log.status) === 1
                            ? "<span class='badge badge-pill badge-success'>Thành công</span>"
                            : parseInt(log.status) === 0
                            ? "<span class='badge badge-pill badge-warning'>Đang chạy </span>"
                            : "<span class='badge badge-pill badge-danger'>Thất bại</span>"
                    let oldCoin = Number(log.old_coin)
                    let newCoin = Number(log.new_coin)
                    let changeCoin = Number(log.new_coin) - Number(log.old_coin)
                    log.changeCoin = changeCoin
                    log.coin_html =
                        "<span class='py-1 badge badge-yellow'>" + oldCoin.toLocaleString("it-IT") + "</span>"
                    log.coin_html +=
                        changeCoin >= 0
                            ? " + <span class='py-1 badge badge-success'>" +
                              changeCoin.toLocaleString("it-IT") +
                              "</span>"
                            : " - <span class='py-1 badge badge-danger'>" +
                              (changeCoin * -1).toLocaleString("it-IT") +
                              "</span>"
                    log.coin_html +=
                        " = <span class='badge badge-pill badge-primary'>" + newCoin.toLocaleString("it-IT") + "</span>"
                    return log
                })
            }
        }
    }
}
</script>
