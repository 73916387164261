<template>
    <div>
        <datatable :columns="columnsClient" :rows="listClientsManipulated">
            <th v-if="isDungQb" slot="thead-tr-o">
                Site đại lý
            </th>
            <template v-if="isDungQb" slot="tbody-tr-o" slot-scope="props">
                <td>
                    <toggle-button
                        @change="updateInfo(props.row)"
                        :value="props.row.active_site == 1 ? true : false"
                        :labels="{ checked: 'BẬT', unchecked: 'TẮT' }"
                        :sync="true"
                        color="#23C56C"
                        :height="18"
                        :width="49"
                    />
                </td>
            </template>
            <th slot="thead-tr">
                Thao Tác
            </th>
            <template slot="tbody-tr" slot-scope="props">
                <td>
                    <button @click="viewClientHistory(props.row)" type="button" class="btn btn-success btn-xs mr-2">
                        Xem nhật ký
                    </button>

                    <button
                        @click="editUser(props.row)"
                        type="button"
                        class="btn btn-orange btn-xs mr-2"
                        data-toggle="modal"
                        href="#level-modal"
                    >
                        <i class="fa fa-user-circle-o"></i>
                        Cài Đặt Đại Lý
                    </button>
                    <button
                        @click="editUser(props.row)"
                        type="button"
                        class="btn btn-dark-blue btn-xs mr-2"
                        data-toggle="modal"
                        href="#modal-update"
                    >
                        <i class="fa fa-list"></i>
                        Chỉnh Sửa
                    </button>
                    <button @click="deleteUser(props.row)" type="button" class="btn btn-danger btn-xs mr-2">
                        <i class="fa fa-close"></i>
                        Xóa
                    </button>
                    <button
                        @click="updateUserStatus(props.row, 1, 'Mở khóa ')"
                        type="button"
                        class="btn btn-info btn-xs"
                        v-if="props.row.status == 0"
                    >
                        <i class="fab fa-opera"></i>
                        Mở khóa
                    </button>
                    <button
                        v-else
                        @click="updateUserStatus(props.row, 0, 'Khóa ')"
                        type="button"
                        class="btn btn-primary btn-xs"
                    >
                        <i class="fab fa-opera"></i>
                        Khóa
                    </button>
                </td>
            </template>
        </datatable>
        <clientchangelevel :levelList="levelList" :selectedUser="selectedUser"></clientchangelevel>
        <clientupdate :selectedUser="selectedUser"></clientupdate>
    </div>
</template>
<script>
import datatable from "../../../components/datatable/datatable"
import clientchangelevel from "./client-change-level"
import clientupdate from "./client-update"
import { catchError } from "../../../helpers"
import { deleteUser, updateUser, updateUserStatus } from "@/api/admin"
export default {
    name: "client-list-user",
    components: {
        datatable,
        clientchangelevel,
        clientupdate
    },
    props: {
        levelList: Array,
        clients: Array
    },
    data() {
        return {
            columnsClient: [
                { label: "#", field: "index" },
                // { label: "Id", field: "id" },
                { label: "UserName", field: "username" },
                { label: "Tiền (vnđ)", field: "coin", numeric: true },
                { label: "Level", field: "level_html", html: true },
                { label: "Trạng thái", field: "status_text", html: true },
                { label: "Email", field: "email", html: true },
                { label: "SDT", field: "phone", html: true },
                { label: "Ngày Tạo", field: "created_at", timeago: true }
            ],
            listClients: [],
            selectedUser: {}
        }
    },
    computed: {
        user() {
            return this.$store.state.user.user
        },
        isMobile() {
            return this.$store.state.app.isMobile
        },
        site() {
            return this.$store.state.site.site
        },
        isDungQb() {
            return true
        },
        listClientsManipulated() {
            return this.listClients.map(client => {
                if (client.status == 1) {
                    client.status_text = "Bình thường"
                    client.color = false
                }
                if (client.status == 0) {
                    client.status_text = "Khóa"
                    client.color = true
                }
                return client
            })
        }
    },
    watch: {
        clients(value) {
            this.listClients = value
        }
    },
    created() {
        this.listClients = this.clients
    },
    methods: {
        viewClientHistory(client = {}) {
            const { username } = client
            let route = this.$router.resolve({ path: `/report?username=${username}&limit=300` })
            window.open(route.href, "_blank")
        },
        editUser: function(client) {
            this.selectedUser = Object.assign({}, client)
        },
        updateInfo: async function(user) {
            let postData = {
                id: user.id,
                active_site: !user.active_site
            }
            let data = await updateUser(user.id, postData)
            if (data.status === 200 && data.data && data.data.id) {
                this.$toastr.success(
                    (data.data.active_site == 1 ? "Bật" : "Tắt") +
                        " mở đại lý cho " +
                        data.data.username +
                        " thành công !"
                )
                this.listClients = this.listClients.map(item => {
                    if (item.id == data.data.id) {
                        item.active_site = data.data.active_site
                    }
                    return item
                })
            } else {
                this.$swal("Lỗi", catchError(data), "error")
                // eslint-disable-next-line no-undef
                $("#modal-update").modal("hide")
            }
        },
        updateUserStatus: async function(user = {}, status, action) {
            await this.$swal({
                title: "Xác nhận",
                html: `Bạn có chắc chắn muốn <strong>${action}</strong> tài khoản <strong>${user.username}</strong>`,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Hủy"
            }).then(async result => {
                if (result.value) {
                    let postData = {
                        status: status
                    }
                    let data = await updateUserStatus(user.id, postData)
                    if (data.success) {
                        this.$toastr.success(data.message || `${action} thành công`)
                        this.listClients = this.listClients.map(item => {
                            if (item.id == user.id) {
                                item.status = status
                            }
                            return item
                        })
                    } else {
                        this.$swal("Lỗi", catchError(data), "error")
                    }
                }
            })
        },
        deleteUser: async function(selectedUser) {
            let that = this
            let cancel = false
            await this.$swal({
                title: "Xác nhận",
                text: "Bạn có chắc chắn muốn xóa tài khoản " + selectedUser.username,
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Đồng Ý",
                cancelButtonText: "Không",
                reverseButtons: true
            }).then(result => {
                if (result.value) {
                    cancel = true
                }
            })
            if (cancel) {
                let data = await deleteUser(selectedUser.id)
                if (data.status === 200 && data.success) {
                    this.$swal("Hoàn thành", "Đã xóa thành công tài khoản " + selectedUser.username, "success")
                    that.$store.commit("APP_RELOAD")
                } else {
                    this.$swal("Lỗi", catchError(data), "error")
                }
            }
        }
    }
}
</script>
