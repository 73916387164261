<template>
    <div
        id="level-modal"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        aria-labelledby="levelModal"
        aria-hidden="true"
        style="display: none;"
    >
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title bold">Chỉnh Sửa Level Khách Hàng</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"></button>
                </div>
                <form @submit.stop.prevent="updateLevel">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Tên Tài Khoản</label>
                            <div>
                                <input
                                    type="text"
                                    disabled
                                    class="form-control form-control-line username-text"
                                    v-model="selectedUser.username"
                                />
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Cấp độ hiện tại của khách hàng:</label>
                            <input type="text" disabled v-model="selectedUser.level_html" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Chọn cài đặt cấp độ mới cho khách hàng:</label>
                            <select v-model="chosenLevelId" class="custom-select">
                                <option :value="level.id" v-for="level in levelList" :key="level.id">
                                    {{ level.name }} {{ level.description ? "-" : "" }} {{ level.description }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default waves-effect" data-dismiss="modal">Đóng</button>
                        <button type="submit" :class="['btn btn-dark-blue waves-effect waves-light']">
                            Tạo Tiến Trình
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { updateUserLevel } from "@/api/admin"
import { catchError } from "../../../helpers"

export default {
    name: "client-level",
    props: {
        selectedUser: Object,
        levelList: Array
    },
    data() {
        return {
            active_site: 0,
            chosenLevelId: -1
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        },
        site() {
            return this.$store.state.site
        }
    },
    created() {},
    watch: {
        selectedUser() {
            let userLevel = this.selectedUser.levels
                ? this.selectedUser.levels.find(lv => lv.site_id === this.site.id)
                : {}
            if (userLevel) {
                this.chosenLevelId = userLevel.id
            } else this.chosenLevelId = 0
        }
    },
    methods: {
        updateLevel: async function() {
            if (this.chosenLevelId === -1) {
                this.$swal("Lỗi", "Vui lòng chọn level khách hàng trước khi tiến hành tạo tiến trình", "error")
                return
            }
            let postData = {
                username: this.selectedUser.username,
                level_id: this.chosenLevelId
            }
            let data = await updateUserLevel(postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành Công", "Cập nhật level cho " + this.selectedUser.username + " thành công", "success")
                // eslint-disable-next-line no-undef
                $("#level-modal").modal("hide")
                this.$store.commit("APP_RELOAD")
                this.$store.dispatch("USER_GET_INFO")
                this.$store.dispatch("GET_TOOLS")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
                // eslint-disable-next-line no-undef
                $("#level-modal").modal("hide")
            }
        }
    }
}
</script>
