<template>
    <div class="modal fade" id="client-trans-coin">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title bold">Nạp Tiền</h4>
                </div>
                <form class="form-horizontal" @submit.stop.prevent="transCoin">
                    <div class="modal-body">
                        <div class="form-group">
                            <label class="mb-2">Tên người nhận:</label>
                            <v-select
                                label="username"
                                v-model="selectedUser"
                                :options="users"
                                @search="onSearch"
                                placeholder="Nhập username cần tìm"
                            >
                                <template slot="no-options">
                                    Không tìm thấy danh sách User...
                                </template>
                            </v-select>
                        </div>
                        <div class="form-group mb-3">
                            <label
                                >Số tiền hiện tại:
                                <span class="text-primary ml-2" v-if="selectedUser"
                                    ><b>{{ selectedUser.coin }} {{ currency }}</b></span
                                >
                            </label>
                        </div>
                        <div class="form-group">
                            <label for="type"
                                >Số tiền cần nạp:
                                <span v-if="quantity">{{ quantity }} {{ currency }}</span>
                            </label>
                            <div class="input-group">
                                <input
                                    v-model.number="quantity"
                                    type="number"
                                    placeholder="Số tiền cần nạp"
                                    class="form-control select-right"
                                    name="example-email"
                                    min="1"
                                />
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        {{ currency }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary px-4" data-dismiss="modal">Đóng</button>
                        <button type="submit" class="btn btn-dark-blue px-4">Nạp Tiền</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
import { catchError } from "../../../helpers"
import { updateUser, getAllUser } from "@/api/admin"
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
import _ from "lodash"
export default {
    name: "modal-trans-coin",
    props: {
        clients: Array
    },
    components: {
        vSelect
    },
    data() {
        return {
            selectedUser: "",
            description: "",
            quantity: 0,
            users: []
        }
    },
    watch: {
        selectedUser(nv) {
            if (!nv) {
                this.selectedUser = ""
            }
        },
        reload() {
            this.selectedUser = ""
            this.quantity = 0
        },
        clients() {
            this.selectedUser = ""
            this.quantity = 0
            this.users = this.clients.map(user => {
                user.value = user
                return user
            })
        }
    },
    computed: {
        currency() {
            return this.$store.state.site.site.currency || "Xu"
        }
    },
    created() {
        this.users = this.clients.map(user => {
            user.value = user
            return user
        })
    },
    methods: {
        onSearch(search, loading) {
            if (search.length > 0) {
                this.selectedUser = ""
                loading(true)
                this.search(loading, search, this)
            }
        },
        search: _.debounce(async (loading, search, vm) => {
            await vm.getAllUser(search)
            loading(false)
        }, 500),
        transCoin: async function() {
            if (!this.selectedUser) {
                this.$swal("Lỗi", "Bạn chưa chọn User cần nạp tiền!", "error")
                return
            }
            let postData = {
                coin: this.quantity + this.selectedUser.coin
            }
            let data = await updateUser(this.selectedUser.id, postData)
            if (data.status === 200 && data.success) {
                this.$swal("Thành công", "Nạp tiền thành công", "success")
                // eslint-disable-next-line no-undef
                $("#client-trans-coin").modal("hide")
                this.$emit("getAllUser")
                // this.$store.commit("APP_RELOAD")
            } else {
                this.$swal("Lỗi", catchError(data), "error")
            }
        },
        getAllUser: async function(username = null) {
            this.users = []
            let param = username ? "&username=" + username : ""
            this.showLoading = true
            let data = await getAllUser(100, param)
            if (data.status === 200 && data.success) {
                this.users = data.data.map((client, index) => {
                    client.index = index + 1
                    client.coin_html = client.coin
                    client.value = client
                    return client
                })
            }
            this.showLoading = false
        }
    }
}
</script>
